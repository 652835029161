<template>
  <div class="card p-shadow-6">
    <h1>Instalações</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Dialog
      :visible.sync="statusMenu.show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="
        statusMenu.installation
          ? `Alterar Estado da Instalção ${statusMenu.installation.id} | ${statusMenu.installation.description}`
          : ''
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <Button
        label="Pendente"
        icon="pi pi-hourglass"
        class="p-button-sm p-mr-2"
        :style="getGroupheaderColor({ toDo: 'Pendente' })"
        @click="setPending(statusMenu.installation)"
      />
      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Folha de Instalação por Concluir'
        "
        label="Concluir Folha de instalação"
        icon="pi pi-external-link"
        class="p-button-sm"
        :style="
          getGroupheaderColor({ toDo: 'Folha de Instalação por Concluir' })
        "
        @click="goInstallationSheet(statusMenu.installation)"
      />

      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Em Piloto Sem Data'
        "
        label="Inserir Data de Piloto"
        icon="pi pi-calendar-plus"
        class="p-button-sm"
        :style="getGroupheaderColor({ toDo: 'Em Piloto Sem Data' })"
        @click="setPilotDate(statusMenu.installation)"
      />
      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Por Concluir'
        "
        label="Concluir Instalação"
        icon="pi pi-check"
        class="p-button-sm"
        :style="getGroupheaderColor({ toDo: 'Por Concluir' })"
        @click="setConclude(statusMenu.installation)"
      />
      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'A Decorrer'
        "
        label="Inserir Data de Entrega"
        icon="pi pi-calendar-plus"
        class="p-button-sm"
        :style="getGroupheaderColor({ toDo: 'A Decorrer' })"
        @click="setDeliveryDate(statusMenu.installation)"
      />
      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Sem Relatório'
        "
        label="Inserir Data de Relatório"
        icon="pi pi-calendar-plus"
        class="p-button-sm"
        :style="getGroupheaderColor({ toDo: 'Sem Relatório' })"
        @click="setReportDate(statusMenu.installation)"
      />
      <Button
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Sem Marcação'
        "
        label="Marcar Instalação"
        icon="pi pi-send"
        class="p-button-sm"
        :style="getGroupheaderColor({ toDo: 'Sem Marcação' })"
        @click="setMarked(statusMenu.installation)"
      />

      <div
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Sem Folha de Instalação'
        "
      >
        <Message severity="info" :closable="false">
          A funcionalidade de criar Folhas de Instalação ainda não foi
          desenvolvida na intranet.
          <br />
          <b>Para criar a Folha de Instalação deverá utilizar o PHC.</b>
        </Message>
      </div>

      <div
        v-if="
          statusMenu.installation &&
          statusMenu.installation.toDo == 'Sem Folha de Obra'
        "
      >
        <Message severity="info" :closable="false">
          A funcionalidade de criar Folhas de Obra ainda não foi desenvolvida na
          intranet.
          <br />
          <b>Para criar a Folha de Obra deverá utilizar o PHC.</b>
        </Message>
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeStatusMenu()"
        />
      </template>
    </Dialog>

    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4MstreakIndicators
        :indicators="streakIndicators"
        :loader="indicatorLoading"
        v-on:W4MstreakIndicatorsClick="streakIndicatorsClick"
      />
    </div>
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        :states="streakStatus"
        :loader="streakLoading"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        v-if="showStreakPilot"
        :states="streakPilotStatus"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div class="p-grid p-fluid dashboard p-jc-center">
      <W4Mstreak
        v-if="showStreakPendent"
        :states="streakPendentStatus"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <InstallationChangeTechnician
      :showToast="true"
      :show="changeTecResponsible.show"
      :installationId="changeTecResponsible.installationId"
      :currentTechnician="changeTecResponsible.currentTechnician"
      v-on:cancel="changeTecResposibleCancel"
      v-on:setTechnicianInstallation="changedTecResposible"
    />
    <InstallationChangeResponsible
      :showToast="true"
      :show="changeResponsible.show"
      :installationId="changeResponsible.installationId"
      :currentTechnician="changeResponsible.currentTechnician"
      v-on:cancel="changeResposibleCancel"
      v-on:setResponsibleInstallation="changedResposible"
    />
    <InstallationChangeManager
      :showToast="true"
      :show="managerChange.show"
      :installationId="managerChange.installationId"
      :currentManager="managerChange.currentResponsable"
      v-on:cancel="changeManagerCancel"
      v-on:setInstallationManager="changedManager"
    />
    <InstallationChangeSeller
      :showToast="true"
      :show="sellerChange.show"
      :installationId="sellerChange.installationId"
      :currentSeller="sellerChange.currentResponsable"
      v-on:cancel="changeSellerCancel"
      v-on:setInstallationSeller="changedSeller"
    />
    <InstallationSetPending
      :showToast="true"
      :show="changeStatusToPending.show"
      :installationId="changeStatusToPending.installationId"
      :installationName="changeStatusToPending.installationName"
      v-on:cancel="setPendingCancel"
      v-on:setPendingInstallation="setPendingOk"
    />
    <InstallationRemovePending
      :showToast="true"
      :show="changeStatusRemovePending.show"
      :installationId="changeStatusRemovePending.installationId"
      :installationName="changeStatusRemovePending.installationName"
      v-on:cancel="removePendingCancel"
      v-on:removedPendingInstallation="removePendingOk"
    />
    <InstallationSetPilotDate
      :showToast="true"
      :show="changeStatusSetPilotDate.show"
      :installationId="changeStatusSetPilotDate.installationId"
      :installationName="changeStatusSetPilotDate.installationName"
      v-on:cancel="setPilotDateCancel"
      v-on:addedPilotDate="setPilotDateOk"
    />
    <InstallationSetConclude
      :showToast="true"
      :show="changeStatusSetConclude.show"
      :installationId="changeStatusSetConclude.installationId"
      :installationName="changeStatusSetConclude.installationName"
      v-on:cancel="setConcludeCancel"
      v-on:completedInstallation="setConcludeOk"
    />
    <InstallationSetDeliveryDate
      :showToast="true"
      :show="changeStatusSetDeliveryDate.show"
      :installationId="changeStatusSetDeliveryDate.installationId"
      :installationName="changeStatusSetDeliveryDate.installationName"
      v-on:cancel="setDeliveryDateCancel"
      v-on:addedDeliveryDate="setDeliveryDateOk"
    />
    <InstallationSetReportDate
      :showToast="true"
      :show="changeStatusSetReportDate.show"
      :installationId="changeStatusSetReportDate.installationId"
      :installationName="changeStatusSetReportDate.installationName"
      v-on:cancel="setReportDateCancel"
      v-on:addedReportDate="setReportDateOk"
    />
    <InstallationSetMarked
      :showToast="true"
      :show="changeStatusSetMarked.show"
      :installationId="changeStatusSetMarked.installationId"
      :installationName="changeStatusSetMarked.installationName"
      v-on:cancel="setMarkedCancel"
      v-on:marked="setMarkedOk"
    />
    <InstallationComments
      :show="viewInstallationComments.show"
      :installationId="viewInstallationComments.installationId"
      :installationName="viewInstallationComments.installationName"
      v-on:cancel="viewCommentsClose"
    />
    <div style="height: 65vh">
      <DataTable
        :value="installationsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="streakIndex"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        groupRowsBy="toDo"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        @rowgroup-expand="rowExpand"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm"
              label="Mostrar todas as Instalações"
              @click="showAllInstallations()"
            />
            <span style="position: absolute; right: 10px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="search"
                  type="text"
                  placeholder="Pesquisar"
                  class="p-inputtext-sm"
                  @keyup.enter="searchInstallations"
                />
              </span>
            </span>
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.toDo }}
          </div>
        </template>
        <Column
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Instalação'"
              @click="goInstallationsInfo(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="description"
          header="Instalação"
          filterField="description"
          :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.description }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="name"
          header="Nome"
          filterField="name"
          :headerStyle="{ 'min-width': '14%', 'max-width': '14%' }"
          :filterHeaderStyle="{ 'min-width': '14%', 'max-width': '14%' }"
          :bodyStyle="{ 'min-width': '14%', 'max-width': '14%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          header="Estado"
          :headerStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :filterHeaderStyle="{ 'min-width': '12%', 'max-width': '12%' }"
          :bodyStyle="{ 'min-width': '12%', 'max-width': '12%' }"
        >
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data)"
            ></i>
            <span
              v-if="slotProps.data.toDo == 'Em Piloto'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado para Pendente'"
              @click="setPending(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span
              v-else-if="slotProps.data.toDo.includes('Pendente')"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Retirar de Pendente'"
              @click="removePending(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span
              v-else-if="
                slotProps.data.toDo == 'Folha de Instalação por Concluir' ||
                slotProps.data.toDo == 'Sem Marcação' ||
                slotProps.data.toDo == 'A Decorrer' ||
                slotProps.data.toDo == 'Sem Folha de Obra' ||
                slotProps.data.toDo == 'Por Concluir' ||
                slotProps.data.toDo == 'Em Piloto Sem Data' ||
                slotProps.data.toDo == 'Sem Folha de Instalação' ||
                slotProps.data.toDo == 'Sem Relatório' ||
                slotProps.data.toDo == 'Por Validar'
              "
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="showStatusMenu(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span v-else>{{ slotProps.data.toDo }}</span>
          </template>
        </Column>
        <Column
          sortable
          field="managerOffice"
          header="Zona"
          filterField="managerOffice"
          :headerStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :filterHeaderStyle="{ 'min-width': '11%', 'max-width': '11%' }"
          :bodyStyle="{ 'min-width': '11%', 'max-width': '11%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.managerOffice }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { zone: 'Faro', zoneValue: 'Faro' },
                { zone: 'Lisboa', zoneValue: 'Lisboa' },
                { zone: 'Porto', zoneValue: 'Porto' },
                { zone: 'Sem Zona', zoneValue: '___' },
              ]"
              optionValue="zoneValue"
              optionLabel="zone"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          header="Material"
          :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.materialToBeTreated != undefined &&
                slotProps.data.materialToBeTreated.length > 0
              "
              class="pi pi-exclamation-triangle"
              :style="{ color: '#f23e3e' }"
              v-tooltip="'Material por Tratar'"
            />
            <span
              v-if="
                slotProps.data.materialToOrder != undefined &&
                slotProps.data.materialToOrder.length > 0
              "
              class="pi pi-exclamation-triangle"
              :style="{ color: '#3b00ff' }"
              v-tooltip="'Material por Encomendar'"
            />
            <span
              v-if="
                slotProps.data.materialToBeReceived != undefined &&
                slotProps.data.materialToBeReceived.length > 0
              "
              class="pi pi-exclamation-triangle"
              :style="{ color: '#ffe100' }"
              v-tooltip="'Material por Receber'"
            />
            <span
              v-if="
                slotProps.data.materialToBeReceivedPending != undefined &&
                slotProps.data.materialToBeReceivedPending.length > 0
              "
              class="pi pi-exclamation-triangle"
              :style="{ color: '#00ffcb' }"
              v-tooltip="'Material por Receber Pendente'"
            />
          </template>
        </Column>
        <Column
          field="commentsNumber"
          header="Comentários"
          :headerClass="'align-center'"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{
            'min-width': '8.5%',
            'max-width': '8.5%',
            'justify-content': 'center',
          }"
        >
          <template #body="slotProps">
            <i
              v-if="slotProps.data.commentsNumber > 0"
              class="pi pi-comments clickable-item"
              v-tooltip="'Ver comentários'"
              @click="viewComments(slotProps.data)"
            ></i>
          </template>
        </Column>
        <!-- <Column
          sortable
          field="seller"
          header="Vendedor"
          filterField="seller"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.username == slotProps.data.seller
              "
              class="clickable-item"
              v-tooltip="'Alterar Vendedor'"
              @click="changeSeller(slotProps.data)"
              >{{
                slotProps.data.seller == "" ? "_______" : slotProps.data.seller
              }}</span
            >
            <span v-else>{{ slotProps.data.seller }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column> -->
        <!-- <Column field="manager" header="Gestor" filterField="manager">
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.username == slotProps.data.manager
              "
              class="clickable-item"
              v-tooltip="'Alterar Gestor'"
              @click="changeManager(slotProps.data)"
              >{{
                slotProps.data.manager == ""
                  ? "_______"
                  : slotProps.data.manager
              }}</span
            >
            <span v-else>{{ slotProps.data.manager }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column> -->
        <Column
          sortable
          field="responsibleTechnical"
          header="Responsável"
          filterField="responsibleTechnical"
          :headerStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :filterHeaderStyle="{ 'min-width': '10%', 'max-width': '10%' }"
          :bodyStyle="{ 'min-width': '10%', 'max-width': '10%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.username == slotProps.data.responsibleTechnical
              "
              class="clickable-item"
              v-tooltip="'Alterar Responsável'"
              @click="changeResp(slotProps.data)"
              >{{
                slotProps.data.responsibleTechnical == ""
                  ? "_______"
                  : slotProps.data.responsibleTechnical
              }}</span
            >
            <span v-else>{{ slotProps.data.responsibleTechnical }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <!-- <Column
          field="technician"
          header="Técnico Resp."
          filterField="technician"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.username == slotProps.data.technician
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável'"
              @click="changeTecResposible(slotProps.data)"
              >{{
                slotProps.data.technician == ""
                  ? "_______"
                  : slotProps.data.technician
              }}</span
            >
            <span v-else>{{ slotProps.data.technician }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column> -->
        <Column
          sortable
          field="creationDate"
          header="Data Criação"
          :headerStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :filterHeaderStyle="{ 'min-width': '7%', 'max-width': '7%' }"
          :bodyStyle="{ 'min-width': '7%', 'max-width': '7%' }"
        >
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import searchService from "../services/search.service";
import installationsService from "../services/installations.service";
import { FilterMatchMode } from "primevue/api/";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import W4MstreakIndicators from "../components/W4Mstreak/W4MstreakIndicators.vue";
import InstallationChangeTechnician from "../components/installations/ChangeTechnician.vue";
import InstallationChangeResponsible from "../components/installations/ChangeResponsible.vue";
import InstallationChangeManager from "../components/installations/ChangeManager.vue";
import InstallationChangeSeller from "../components/installations/ChangeSeller.vue";
import InstallationSetPending from "../components/installations/SetPending.vue";
import InstallationRemovePending from "../components/installations/RemovePending.vue";
import InstallationSetPilotDate from "../components/installations/SetPilotDate.vue";
import InstallationSetConclude from "../components/installations/SetConclude.vue";
import InstallationSetDeliveryDate from "../components/installations/SetDeliveryDate.vue";
import InstallationSetReportDate from "../components/installations/SetReportDate.vue";
import InstallationSetMarked from "../components/installations/SetMarked.vue";
import InstallationComments from "../components/installations/Comments.vue";

export default {
  name: "Installations",
  components: {
    W4Mstreak,
    W4MstreakIndicators,
    InstallationChangeTechnician,
    InstallationChangeResponsible,
    InstallationChangeManager,
    InstallationChangeSeller,
    InstallationSetPending,
    InstallationRemovePending,
    InstallationSetPilotDate,
    InstallationSetConclude,
    InstallationSetDeliveryDate,
    InstallationSetReportDate,
    InstallationSetMarked,
    InstallationComments,
  },
  data() {
    return {
      search: null,
      streakStatus: [],
      streakIndicators: [],
      streakPilotStatus: [],
      showStreakPilot: false,
      streakPendentStatus: [],
      showStreakPendent: false,
      installationsList: [],
      expandedRowGroups: [],
      loading: false,
      indicatorLoading: false,
      streakLoading: false,
      filters: {
        business: { value: null, matchMode: FilterMatchMode.CONTAINS },
        managerOffice: { value: null, matchMode: FilterMatchMode.EQUALS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        entity: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        manager: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        observation: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pending: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsibleTechnical: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        situationStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        toDo: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      statusMenu: { show: false, installation: null },
      changeTecResponsible: {
        show: false,
        installationId: null,
        currentResponsable: 0,
      },
      changeResponsible: {
        show: false,
        installationId: null,
        currentResponsable: 0,
      },
      managerChange: {
        show: false,
        installationId: null,
        currentResponsable: 0,
      },
      sellerChange: {
        show: false,
        installationId: null,
        currentResponsable: 0,
      },
      changeStatusToPending: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusRemovePending: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusSetPilotDate: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusSetConclude: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusSetDeliveryDate: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusSetReportDate: {
        show: false,
        installationId: null,
        installationName: null,
      },
      changeStatusSetMarked: {
        show: false,
        installationId: null,
        installationName: null,
      },
      viewInstallationComments: {
        show: false,
        installationId: null,
        installationName: null,
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async beforeCreate() {},
  async created() {
    if (
      this.$route.params.status != undefined &&
      this.$route.params.status != ""
    ) {
      this.filters.responsibleTechnical.value = this.loggedUser.username;
    }
    await this.initStreakStatus();
    this.initStreakIndicators();
  },
  methods: {
    async initStreakIndicators() {
      this.streakIndicators = [
        {
          title: "Piloto",
          counter: 0,
        },
        {
          title: "Sem Folha de Instalação",
          counter: 0,
        },
        {
          title: "Material por Tratar",
          counter: 0,
          iconColor: "#f23e3e",
          icon: "pi pi-exclamation-triangle",
        },
        {
          title: "Material por Encomendar",
          counter: 0,
          iconColor: "#3b00ff",
          icon: "pi pi-exclamation-triangle",
        },
        {
          title: "Material por Receber",
          counter: 0,
          iconColor: "#ffe100",
          icon: "pi pi-exclamation-triangle",
        },
        {
          title: "Material por Receber Pendente",
          counter: 0,
          iconColor: "#00ffcb",
          icon: "pi pi-exclamation-triangle",
        },
        // {
        //   title: "A Decorrer",
        //   counter: 0,
        // },
        {
          title: "Folha de Instalação por Concluir",
          counter: 0,
        },
        {
          title: "Sem Folha de Obra",
          counter: 0,
        },
        {
          title: "Sem Relatório",
          counter: 0,
        },
      ];
      // if (
      //   this.$store.state.auth.user.isAdmin ||
      //   this.$store.state.auth.user.isEsa
      // ) {
      //   this.streakIndicators.push({
      //     title: "Por Validar",
      //     counter: 0,
      //   });
      // }
      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";

      if (activeStatus != "") {
        let activeStatusIndex =
          this.getStreakIndicatorStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }

      this.indicatorLoading = true;
      let withoutInstallSheet =
        await installationsService.countWithoutInstallationSheet();
      let unfinishedInstallationSheet =
        await installationsService.countInstallationSheetToBeCompleted();
      // let noDeliveryDate =
      //   await installationsService.countWithoutDeliveryDate();
      let noWorksheet = await installationsService.countWithoutWorksheet();
      let noDateReport = await installationsService.countUndatedReport();
      let pilot = {
        inPilot: await installationsService.countInPiloto(),
        inPilotUndated: await installationsService.countInPilotUndated(),
      };
      pilot.total = {
        total: pilot.inPilot.total + pilot.inPilotUndated.total,
        user: pilot.inPilot.user + pilot.inPilotUndated.user,
      };
      this.streakIndicators = [
        {
          valueBackgroudColor: "#5F78DA",
          valueColor: "#FFF",
          title: "Piloto",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pilot.total.total
              : pilot.total.user,
        },
        {
          valueBackgroudColor: "#5F78DA",
          valueColor: "#FFF",
          title: "Sem Folha de Instalação",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? withoutInstallSheet.total
              : withoutInstallSheet.user,
        },
        {
          title: "Material por Tratar",
          icon: "pi pi-exclamation-triangle",
          iconColor: "#f23e3e",
          counter: await installationsService.countMaterialToBeTreated(),
        },
        {
          title: "Material por Encomendar",
          icon: "pi pi-exclamation-triangle",
          iconColor: "#3b00ff",
          counter: await installationsService.countMaterialToOrder(),
        },
        {
          title: "Material por Receber",
          icon: "pi pi-exclamation-triangle",
          iconColor: "#ffe100",
          counter: await installationsService.countMaterialToBeReceived(),
        },
        {
          title: "Material por Receber Pendente",
          icon: "pi pi-exclamation-triangle",
          iconColor: "#00ffcb",
          counter:
            await installationsService.countMaterialToBeReceivedPending(),
        },
        // {
        //   title: "A Decorrer",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? noDeliveryDate.total
        //       : noDeliveryDate.user,
        // },
        {
          title: "Folha de Instalação por Concluir",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? unfinishedInstallationSheet.total
              : unfinishedInstallationSheet.user,
        },
        {
          title: "Sem Folha de Obra",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? noWorksheet.total
              : noWorksheet.user,
        },
        {
          title: "Sem Relatório",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? noDateReport.total
              : noDateReport.user,
        },
      ];
      // if (
      //   this.$store.state.auth.user.isAdmin ||
      //   this.$store.state.auth.user.isEsa
      // ) {
      //   let technicianToValidate =
      //     await installationsService.countTechnicianToValidate();
      //   this.streakIndicators.push({
      //     title: "Por Validar",
      //     counter:
      //       this.loggedUser.isEsa || this.loggedUser.isAdmin
      //         ? technicianToValidate.total
      //         : technicianToValidate.user,
      //   });
      // }
      return (this.indicatorLoading = false);
    },
    async initStreakStatus() {
      this.streakLoading = true;
      let pilot = {
        inPilot: await installationsService.countInPiloto(),
        inPilotUndated: await installationsService.countInPilotUndated(),
      };
      pilot.total = {
        total: pilot.inPilot.total + pilot.inPilotUndated.total,
        user: pilot.inPilot.user + pilot.inPilotUndated.user,
      };
      let unmarked = await installationsService.countUnmarked();
      let marked = await installationsService.countWithMarkup();
      // let withoutInstallSheet =
      //   await installationsService.countWithoutInstallationSheet();
      // let unfinishedInstallationSheet =
      //   await installationsService.countInstallationSheetToBeCompleted();
      let noDeliveryDate =
        await installationsService.countWithoutDeliveryDate();
      // let noWorksheet = await installationsService.countWithoutWorksheet();
      // let noDateReport = await installationsService.countUndatedReport();
      let pending = {
        deliveredPending: await installationsService.countDeliveredPending(),
        pendingClient: await installationsService.countPendingClient(),
        pendingW4M: await installationsService.countPendingW4M(),
      };
      pending.total = {
        total:
          pending.deliveredPending.total +
          pending.pendingClient.total +
          pending.pendingW4M.total,
        user:
          pending.deliveredPending.user +
          pending.pendingClient.user +
          pending.pendingW4M.user,
      };

      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      // this.streakPilotStatus = [
      //   // {
      //   //   backgroudColor: "#ffd13a",
      //   //   textColor: "#000",
      //   //   title: "Em Piloto",
      //   //   counter:
      //   //     this.loggedUser.isEsa || this.loggedUser.isAdmin
      //   //       ? pilot.inPilot.total
      //   //       : pilot.inPilot.user,
      //   //   active: activeStatus === "Em Piloto",
      //   //   data: [],
      //   // },
      //   {
      //     backgroudColor: "#ffdb72",
      //     textColor: "#000",
      //     title: "Em Piloto Sem Data",
      //     counter:
      //       this.loggedUser.isEsa || this.loggedUser.isAdmin
      //         ? pilot.inPilotUndated.total
      //         : pilot.inPilotUndated.user,
      //     active: activeStatus === "Em Piloto Sem Data",
      //     data: [],
      //   },
      // ];
      this.streakPendentStatus = [
        {
          backgroudColor: "#e5dd3b",
          textColor: "#000",
          title: "Entregues Pendentes",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pending.deliveredPending.total
              : pending.deliveredPending.user,
          active: activeStatus === "Entregues Pendentes",
          data: [],
        },
        {
          backgroudColor: "#e0db76",
          textColor: "#000",
          title: "Pendente Cliente",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pending.pendingClient.total
              : pending.pendingClient.user,
          active: activeStatus === "Pendente Cliente",
          data: [],
        },
        {
          backgroudColor: "#dbd8a4",
          textColor: "#000",
          title: "Pendente W4M",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pending.pendingW4M.total
              : pending.pendingW4M.user,
          active: activeStatus === "Pendente W4M",
          data: [],
        },
      ];
      this.streakStatus = [
        // {
        //   backgroudColor: "#FCC200",
        //   textColor: "#000",
        //   title: "Piloto",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? pilot.total.total
        //       : pilot.total.user,
        //   active: activeStatus.includes("Piloto"),
        //   data: [],
        // },
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Sem Marcação",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? unmarked.total
              : unmarked.user,
          active: activeStatus === "Sem Marcação",
        },
        {
          backgroudColor: "#86e053",
          textColor: "#000",
          title: "Marcado",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? marked.total
              : marked.user,
          active: activeStatus === "Marcado",
        },
        // {
        //   backgroudColor: "#5F78DA",
        //   textColor: "#FFF",
        //   title: "Sem Folha de Instalação",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? withoutInstallSheet.total
        //       : withoutInstallSheet.user,
        //   active: activeStatus === "Sem Folha de Instalação",
        // },
        // {
        //   backgroudColor: "#4455BD",
        //   textColor: "#FFF",
        //   title: "Folha de Instalação por Concluir",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? unfinishedInstallationSheet.total
        //       : unfinishedInstallationSheet.user,
        //   active: activeStatus === "Folha de Instalação por Concluir",
        // },
        {
          backgroudColor: "#191D80",
          textColor: "#FFF",
          title: "A Decorrer",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? noDeliveryDate.total
              : noDeliveryDate.user,
          active: activeStatus === "A Decorrer",
        },
        // {
        //   backgroudColor: "#00A2B3",
        //   textColor: "#FFF",
        //   title: "Sem Folha de Obra",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? noWorksheet.total
        //       : noWorksheet.user,
        //   active: activeStatus === "Sem Folha de Obra",
        // },
        // {
        //   backgroudColor: "#00C8B6",
        //   textColor: "#FFF",
        //   title: "Sem Relatório",
        //   counter:
        //     this.loggedUser.isEsa || this.loggedUser.isAdmin
        //       ? noDateReport.total
        //       : noDateReport.user,
        //   active: activeStatus === "Sem Relatório",
        // },
      ];
      this.streakStatus.push({
        backgroudColor: "#ffff3d",
        textColor: "#000",
        title: "Pendente",
        counter:
          this.loggedUser.isEsa || this.loggedUser.isAdmin
            ? pending.total.total
            : pending.total.user,
        active: activeStatus === "Pendente",
      });

      if (
        this.$store.state.auth.user.isAdmin ||
        this.$store.state.auth.user.isEsa
      ) {
        let technicianToValidate =
          await installationsService.countTechnicianToValidate();
        let unfinished = await installationsService.countUnfinished();
        this.streakStatus.push(
          {
            backgroudColor: "#e03d23",
            textColor: "#FFF",
            title: "Por Validar",
            counter:
              this.loggedUser.isEsa || this.loggedUser.isAdmin
                ? technicianToValidate.total
                : technicianToValidate.user,
            active: activeStatus === "Por Validar",
          },
          {
            backgroudColor: "#db7272",
            textColor: "#FFF",
            title: "Por Concluir",
            counter:
              this.loggedUser.isEsa || this.loggedUser.isAdmin
                ? unfinished.total
                : unfinished.user,
            active: activeStatus === "Por Concluir",
          }
        );
      }

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.streakLoading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo;
      if (info.toDo.includes("Piloto")) {
        statusInfo = this.streakPilotStatus.find(
          (status) => status.title == info.toDo
        );
        if (!statusInfo) {
          statusInfo = this.streakStatus.find(
            (status) => status.title == "Piloto"
          );
        }
      } else if (info.toDo.includes("Pendente")) {
        statusInfo = this.streakPendentStatus.find(
          (status) => status.title == info.toDo
        );
        if (!statusInfo) {
          statusInfo = this.streakStatus.find(
            (status) => status.title == "Pendente"
          );
        }
      } else {
        statusInfo = this.streakStatus.find(
          (status) => status.title == info.toDo
        );
      }
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor:
          statusInfo != undefined ? statusInfo.backgroudColor : "",
        borderColor: statusInfo != undefined ? statusInfo.backgroudColor : "",
        color: statusInfo != undefined ? statusInfo.textColor : "",
      };
    },
    getIconColor(info) {
      let statusInfo;
      if (info.toDo.includes("Piloto")) {
        statusInfo = this.streakPilotStatus.find(
          (status) => status.title == info.toDo
        );
      } else if (info.toDo.includes("Pendente")) {
        statusInfo = this.streakPendentStatus.find(
          (status) => status.title == info.toDo
        );
      } else {
        statusInfo = this.streakStatus.find(
          (status) => status.title == info.toDo
        );
      }
      return {
        color: statusInfo != undefined ? statusInfo.backgroudColor : "",
      };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return streakInfo;
      // return this.streakSelectedChange(streakInfo);
    },
    getStreakStatusIndex(status) {
      if (status.includes("Piloto")) {
        return this.streakPilotStatus.findIndex(
          (streakItem) => streakItem.title == status
        );
      }
      if (status.includes("Pendente")) {
        return this.streakPendentStatus.findIndex(
          (streakItem) => streakItem.title == status
        );
      }
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    getStreakIndicatorStatusIndex(status) {
      return this.streakIndicators.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      if (from.includes("Pendente")) {
        let globalPending = this.streakStatus.find(
          (status) => status.title == "Pendente"
        );
        if (globalPending) {
          globalPending.counter = --globalPending.counter;
        }
        let pending = this.streakPendentStatus.find(
          (status) => status.title == from
        );
        if (pending) {
          pending.counter = --pending.counter;
        }
      } else if (from.includes("Piloto")) {
        let globalPilot = this.streakStatus.find(
          (status) => status.title == "Piloto"
        );
        if (globalPilot) {
          globalPilot.counter = --globalPilot.counter;
        }
        let pilot = this.streakPilotStatus.find(
          (status) => status.title == from
        );
        if (pilot) {
          pilot.counter = --pilot.counter;
        }
      } else {
        let statusFrom = this.streakStatus.find(
          (status) => status.title == from
        );
        if (statusFrom) {
          statusFrom.counter = --statusFrom.counter;
        }
      }

      if (to.includes("Pendente")) {
        let toGlobalPending = this.streakStatus.find(
          (status) => status.title == "Pendente"
        );
        if (toGlobalPending) {
          toGlobalPending.counter = ++toGlobalPending.counter;
        }
        let toPending = this.streakPendentStatus.find(
          (status) => status.title == to
        );
        if (toPending) {
          toPending.counter = ++toPending.counter;
        }
      } else if (to.includes("Piloto")) {
        let toGlobalPilot = this.streakStatus.find(
          (status) => status.title == "Piloto"
        );
        if (toGlobalPilot) {
          toGlobalPilot.counter = ++toGlobalPilot.counter;
        }
        let toPilot = this.streakPilotStatus.find(
          (status) => status.title == to
        );
        if (toPilot) {
          toPilot.counter = ++toPilot.counter;
        }
      } else {
        let statusTo = this.streakStatus.find((status) => status.title == to);
        if (statusTo) {
          statusTo.counter = ++statusTo.counter;
        }
      }
    },
    removeByStatus(status) {
      this.installationsList.forEach((installation, index, object) => {
        if (installation.toDo === status) {
          object.splice(index, 1);
        }
      });
    },
    async streakSelectedChange(selected, concatList) {
      if (selected.currentState.includes("Piloto")) {
        this.showStreakPilot = true;
        this.showStreakPendent = false;
        // if (
        //   this.streakPilotStatus[selected.currentStateIndex] != undefined &&
        //   this.streakPilotStatus[selected.currentStateIndex].loaded !=
        //     undefined &&
        //   this.streakPilotStatus[selected.currentStateIndex].loaded == true
        // ) {
        //   return;
        // }
        // if (selected.currentState != "Piloto") {
        //   this.streakPilotStatus[selected.currentStateIndex].loaded = true;
        // }
      } else if (selected.currentState.includes("Pendente")) {
        this.showStreakPilot = false;
        this.showStreakPendent = true;
        // if (
        //   this.streakPendentStatus[selected.currentStateIndex] != undefined &&
        //   this.streakPendentStatus[selected.currentStateIndex].loaded !=
        //     undefined &&
        //   this.streakPendentStatus[selected.currentStateIndex].loaded == true
        // ) {
        //   return;
        // }
        // if (selected.currentState != "Pendente") {
        //   this.streakPendentStatus[selected.currentStateIndex].loaded = true;
        // }
      } else {
        this.showStreakPilot = false;
        this.showStreakPendent = false;
        // if (
        //   this.streakStatus[selected.currentStateIndex].loaded != undefined &&
        //   this.streakStatus[selected.currentStateIndex].loaded == true
        // ) {
        //   return;
        // }
        // this.streakStatus[selected.currentStateIndex].loaded = true;
      }
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Piloto":
          await this.getInPiloto(selected.currentStateIndex, concatList);
          await this.getInPilotUndated(selected.currentStateIndex, true);
          break;
        case "Em Piloto":
          this.getInPiloto(selected.currentStateIndex, concatList);
          break;
        case "Em Piloto Sem Data":
          this.getInPilotUndated(selected.currentStateIndex, concatList);
          break;
        case "Sem Marcação":
          this.getUnmarked(selected.currentStateIndex, concatList);
          break;
        case "Marcado":
          this.getWithMarkup(selected.currentStateIndex, concatList);
          break;
        case "Sem Folha de Instalação":
          this.getWithoutInstallationSheet(
            selected.currentStateIndex,
            concatList
          );
          break;
        case "Folha de Instalação por Concluir":
          this.getInstallationSheetToBeCompleted(
            selected.currentStateIndex,
            concatList
          );
          break;
        case "A Decorrer":
          this.getWithoutDeliveryDate(selected.currentStateIndex, concatList);
          break;
        case "Sem Folha de Obra":
          this.getWithoutWorksheet(selected.currentStateIndex, concatList);
          break;
        case "Por Validar":
          this.getTechnicianToValidate(selected.currentStateIndex, concatList);
          break;
        case "Sem Relatório":
          this.getUndatedReport(selected.currentStateIndex, concatList);
          break;
        case "Por Concluir":
          this.getUnfinished(selected.currentStateIndex, concatList);
          break;
        case "Entregues Pendentes":
          this.getDeliveredPending(selected.currentStateIndex, concatList);
          break;
        case "Pendente Cliente":
          this.getPendingClient(selected.currentStateIndex, concatList);
          break;
        case "Pendente W4M":
          this.getPendingW4M(selected.currentStateIndex, concatList);
          break;
        case "Material por Encomendar":
          this.getInstallationsWithMaterialToOrder(
            selected.currentStateIndex,
            concatList
          );
          break;
        case "Material por Tratar":
          this.getInstallationsWithMaterialToBeTreated(
            selected.currentStateIndex,
            concatList
          );
          break;
        case "Material por Receber":
          this.getInstallationsWithMaterialToBeReceived(
            selected.currentStateIndex,
            concatList
          );
          break;
        case "Material por Receber Pendente":
          this.getInstallationsWithMaterialToBeReceivedPending(
            selected.currentStateIndex,
            concatList
          );
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async showAllInstallations() {
      this.installationsList = [];
      for (const [index, status] of this.streakPilotStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
      for (const [index, status] of this.streakPendentStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange(
          {
            currentStateIndex: index,
            currentState: status.title,
          },
          true
        );
      }
    },
    async getUnmarked(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getUnmarked();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));

      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getWithMarkup(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getWithMarkup();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getWithoutInstallationSheet(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getWithoutInstallationSheet();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInstallationSheetToBeCompleted(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getInstallationSheetToBeCompleted();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getWithoutDeliveryDate(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getWithoutDeliveryDate();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getWithoutWorksheet(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getWithoutWorksheet();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getTechnicianToValidate(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getTechnicianToValidate();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getUndatedReport(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getUndatedReport();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getUnfinished(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getUnfinished();
      list = list.map((v) => ({
        ...v,
        streakIndex: streakIndex,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInPiloto(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getInPiloto();
      list = list.map((v) => ({
        ...v,
        streakIndex: `0${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInPilotUndated(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getInPilotUndated();
      list = list.map((v) => ({
        ...v,
        streakIndex: `0${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getDeliveredPending(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getDeliveredPending();
      list = list.map((v) => ({
        ...v,
        streakIndex: `9${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getPendingClient(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getPendingClient();
      list = list.map((v) => ({
        ...v,
        streakIndex: `9${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getPendingW4M(streakIndex, concatList) {
      this.loading = true;
      let list = await installationsService.getPendingW4M();
      list = list.map((v) => ({
        ...v,
        streakIndex: `9${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInstallationsWithMaterialToBeTreated(streakIndex, concatList) {
      this.loading = true;
      let list =
        await installationsService.getInstallationsWithMaterialToBeTreated();
      list = list.map((v) => ({
        ...v,
        streakIndex: `10${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInstallationsWithMaterialToOrder(streakIndex, concatList) {
      this.loading = true;
      let list =
        await installationsService.getInstallationsWithMaterialToOrder();
      list = list.map((v) => ({
        ...v,
        streakIndex: `10${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInstallationsWithMaterialToBeReceived(streakIndex, concatList) {
      this.loading = true;
      let list =
        await installationsService.getInstallationsWithMaterialToBeReceived();
      list = list.map((v) => ({
        ...v,
        streakIndex: `10${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },
    async getInstallationsWithMaterialToBeReceivedPending(
      streakIndex,
      concatList
    ) {
      this.loading = true;
      let list =
        await installationsService.getInstallationsWithMaterialToBeReceivedPending();
      list = list.map((v) => ({
        ...v,
        streakIndex: `10${streakIndex}`,
      }));
      if (concatList != undefined && concatList === true) {
        this.installationsList = this.installationsList.concat(list);
      } else {
        this.installationsList = list;
      }
      return (this.loading = false);
    },

    goInstallationsInfo(id) {
      let route = this.$router.resolve({
        path: `/installation-details/${id}`,
      });
      window.open(route.href, "_blank");
    },
    changeTecResposible(installation) {
      this.changeTecResponsible.show = true;
      this.changeTecResponsible.installationId = installation.id;
      this.changeTecResponsible.currentTechnician = installation.technician;
      this.changeTecResponsible.installation = installation;
    },
    changeTecResposibleCancel() {
      return (this.changeTecResponsible = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
    changedTecResposible(response) {
      if (response.status == "ok") {
        this.changeTecResponsible.installation.technician =
          response.currenTechnican;
      }
      return this.changeTecResposibleCancel();
    },
    changeResp(installation) {
      this.changeResponsible.show = true;
      this.changeResponsible.installationId = installation.id;
      this.changeResponsible.currentTechnician =
        installation.responsibleTechnical;
      this.changeResponsible.installation = installation;
    },
    changeResposibleCancel() {
      return (this.changeResponsible = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
    changedResposible(response) {
      if (response.status == "ok") {
        this.changeResponsible.installation.responsibleTechnical =
          response.currenTechnican;
      }
      return this.changeResposibleCancel();
    },
    changeManager(installation) {
      this.managerChange.show = true;
      this.managerChange.installationId = installation.id;
      this.managerChange.currentResponsable = installation.manager;
      this.managerChange.installation = installation;
    },
    changeManagerCancel() {
      return (this.managerChange = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
    changedManager(response) {
      if (response.status == "ok") {
        this.managerChange.installation.manager = response.currenTechnican;
        this.managerChange.installation.managerOffice = response.office;
      }
      return this.changeManagerCancel();
    },
    changeSeller(installation) {
      this.sellerChange.show = true;
      this.sellerChange.installationId = installation.id;
      this.sellerChange.currentResponsable = installation.seller;
      this.sellerChange.installation = installation;
    },
    changeSellerCancel() {
      return (this.sellerChange = {
        show: false,
        installationId: null,
        currentTechnician: 0,
      });
    },
    changedSeller(response) {
      if (response.status == "ok") {
        this.sellerChange.installation.seller = response.currenTechnican;
      }
      return this.changeSellerCancel();
    },
    setPending(installation) {
      this.statusMenu.show = false;
      this.changeStatusToPending.show = true;
      this.changeStatusToPending.installationId = installation.id;
      this.changeStatusToPending.installationName = installation.description;
      this.changeStatusToPending.installation = installation;
    },
    setPendingCancel() {
      return (this.changeStatusToPending = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setPendingOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusToPending.installation.toDo,
          response.currenStatus
        );
        this.changeStatusToPending.installation.toDo = response.currenStatus;
      }
      return this.setPendingCancel();
    },
    removePending(installation) {
      this.changeStatusRemovePending.show = true;
      this.changeStatusRemovePending.installationId = installation.id;
      this.changeStatusRemovePending.installationName =
        installation.description;
      this.changeStatusRemovePending.installation = installation;
    },
    removePendingCancel() {
      return (this.changeStatusRemovePending = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    removePendingOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusRemovePending.installation.toDo,
          response.currenStatus
        );
        this.changeStatusRemovePending.installation.toDo =
          response.currenStatus;
      }
      return this.removePendingCancel();
    },
    showStatusMenu(installation) {
      this.statusMenu.show = true;
      this.statusMenu.installation = installation;
    },
    closeStatusMenu() {
      this.statusMenu.show = false;
      this.statusMenu.installation = null;
    },
    goInstallationSheet(installation) {
      let route = this.$router.resolve({
        path: `/task-installation-sheet/${installation.id}/1`,
      });
      this.closeStatusMenu();
      window.open(route.href, "_blank");
    },
    setPilotDate(installation) {
      this.statusMenu.show = false;
      this.changeStatusSetPilotDate.show = true;
      this.changeStatusSetPilotDate.installationId = installation.id;
      this.changeStatusSetPilotDate.installationName = installation.description;
      this.changeStatusSetPilotDate.installation = installation;
    },
    setPilotDateCancel() {
      return (this.changeStatusSetPilotDate = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setPilotDateOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusSetPilotDate.installation.toDo,
          response.currenStatus
        );
        this.changeStatusSetPilotDate.installation.toDo = response.currenStatus;
      }
      return this.setPilotDateCancel();
    },
    setConclude(installation) {
      this.statusMenu.show = false;
      this.changeStatusSetConclude.show = true;
      this.changeStatusSetConclude.installationId = installation.id;
      this.changeStatusSetConclude.installationName = installation.description;
      this.changeStatusSetConclude.installation = installation;
    },
    setConcludeCancel() {
      return (this.changeStatusSetConclude = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setConcludeOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusSetConclude.installation.toDo,
          ""
        );
        this.changeStatusSetConclude.installation.toDo = "";
        this.removeByStatus("");
      }
      return this.setConcludeCancel();
    },
    setDeliveryDate(installation) {
      this.statusMenu.show = false;
      this.changeStatusSetDeliveryDate.show = true;
      this.changeStatusSetDeliveryDate.installationId = installation.id;
      this.changeStatusSetDeliveryDate.installationName =
        installation.description;
      this.changeStatusSetDeliveryDate.installation = installation;
    },
    setDeliveryDateCancel() {
      return (this.changeStatusSetDeliveryDate = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setDeliveryDateOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusSetDeliveryDate.installation.toDo,
          response.currenStatus
        );
        this.changeStatusSetDeliveryDate.installation.toDo =
          response.currenStatus;
      }
      return this.setDeliveryDateCancel();
    },
    setReportDate(installation) {
      this.statusMenu.show = false;
      this.changeStatusSetReportDate.show = true;
      this.changeStatusSetReportDate.installationId = installation.id;
      this.changeStatusSetReportDate.installationName =
        installation.description;
      this.changeStatusSetReportDate.installation = installation;
    },
    setReportDateCancel() {
      return (this.changeStatusSetReportDate = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setReportDateOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusSetReportDate.installation.toDo,
          response.currenStatus
        );
        this.changeStatusSetReportDate.installation.toDo =
          response.currenStatus;
      }
      return this.setReportDateCancel();
    },
    setMarked(installation) {
      this.statusMenu.show = false;
      this.changeStatusSetMarked.show = true;
      this.changeStatusSetMarked.installationId = installation.id;
      this.changeStatusSetMarked.installationName = installation.description;
      this.changeStatusSetMarked.installation = installation;
    },
    setMarkedCancel() {
      return (this.changeStatusSetMarked = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
    setMarkedOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.changeStatusSetMarked.installation.toDo,
          response.currenStatus
        );
        this.changeStatusSetMarked.installation.toDo = response.currenStatus;
      }
      return this.setMarkedCancel();
    },
    streakIndicatorsClick(info) {
      let streakInfo = {
        currentStateIndex: info.indicatorIndex,
        currentState: info.indicatorName,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async searchInstallations() {
      if (this.search == null || this.search == "") {
        return;
      }

      this.loading = true;
      return searchService.installationsV2(this.search).then((response) => {
        if (!response || response.length == 0) {
          return this.$toast.add({
            severity: "info",
            summary: `Resultado da Pesquisa`,
            detail: `Não existem instalações com o termo ${this.search}`,
            life: 3000,
          });
        }
        this.installationsList = response.map((v) => ({
          ...v,
          streakIndex: v.toDo,
        }));
        let todo = this.installationsList.map(
          (installation) => installation.toDo
        );

        this.expandedRowGroups = [...new Set(todo)];
        return (this.loading = false);
      });
    },
    viewComments(installation) {
      console.log(installation);
      this.viewInstallationComments.installationId = installation.id;
      this.viewInstallationComments.installationName = installation.description;
      this.viewInstallationComments.show = true;
    },
    viewCommentsClose() {
      return (this.viewInstallationComments = {
        show: false,
        installationId: null,
        installationName: null,
      });
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}
</style>
